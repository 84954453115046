import React ,{useEffect ,useState ,Suspense} from 'react'
import { Outlet } from 'react-router-dom'

import Header from './Header'
import Hero from './Hero'
import Category from './Category'
import Footer from './Footer'
import Products from './Products'
// const Products = React.lazy(()=>import('./Products'))

import '../assets/styles/main-styles.css'
import Services from './Services'
import Location from './Location'

const Main =()=>{

    const [hideScroll ,setHideScroll] = useState(true)
    const [searched ,setSearched] = useState(false)

window.addEventListener('scroll' ,(e)=>{
    if(window.scrollY > 200){
        setHideScroll(false)
    }else{
        setHideScroll(true)
    }
})
// console.log(JSON.parse(localStorage.getItem("user")))

    return(
        <React.Fragment>
            <Suspense>

                <div className='main-container' id="scroll" >
                    <Hero setSearched={setSearched}/>

                    <Category setSearched={setSearched}/>

                    {/* <Services/> */}
                    <Location setSearched={setSearched} />

                    <Products searched={searched}/><br/>
                    <a href="#scroll" className='toTop' style={{display:hideScroll ? 'none':''}}><i className='fas fa-long-arrow-up'></i></a>
                </div>

            <Footer/>

            </Suspense>
        </React.Fragment>
    )
}

export default Main