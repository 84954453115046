import React,{useLayoutEffect ,useState} from 'react'
import { MDBInput ,MDBInputGroup ,MDBTextArea ,MDBBtn ,MDBBtnGroup} from 'mdb-react-ui-kit'

import { FormattedMessage } from 'react-intl'

import Header from '../Header'
import Footer from '../Footer'
import { HOST_NAME } from '../../config'

const ContactInfo =()=>{

    const [info ,setInfo] = useState({
        email:'',
        message:''
    })
    const [loading ,setLoading] = useState(false)
    const [error ,setError] = useState('')
    const [success ,setSuccess] = useState('')

    useLayoutEffect(()=>{
        window.scrollTo({top:0})
    },[0])

    async function sendMessage(){
        let {email ,message} = info
        if(!email || !message){return}
        setError('')
        setLoading(true)
        setSuccess("")
        try{
            const response = await fetch(`${HOST_NAME}/api/message/receive-client-message` ,{
                method:'post',
                headers:{
                    'content-type':'application/json',
                },
                body:JSON.stringify({email:info.email, message:info.message})
            })
            // const data = await response.json()
            if(response.ok){
                setSuccess(true)
                setInfo({email:'' ,message:''})
            }else{
                setError(true)
            }
        }
        catch(e){
            // console.log(e)
            setError(true)
        }
        finally{
            setLoading(false)
        }
    }

    return(
        <React.Fragment>
            <Header/>
            <div style={{marginBottom:'40px'}}>.</div>

            <div className='main-container' style={{padding:'10px 20px'}} >
                <div>
                    <h3>Contact </h3>
                    {/* <FormattedMessage id="open-available" /> */}
                    <div style={{display:'flex' ,gap:'10px' ,flexWrap:'wrap'}} >
                        <div>
                            <MDBInputGroup className='form-group'>
                            <span>Email &nbsp;&nbsp; <i className='fas fa-envelope'></i></span>
                            <div style={{display:'flex' ,gap:'10px'}}>
                                <span style={{border:'solid 1px rgba(0,0,0,0.1)' ,padding:'5px 10px' ,backgroundColor:'rgba(0,0,0,0.05)' ,borderRadius:'2px'}} >
                                    venamal.contact@gmail.com
                                </span>
                                {/* <MDBInput value={'venamal.contact@gmail.com'} disabled  /> */}
                            </div>
                            </MDBInputGroup>
                        </div>

                        <div>
                            <MDBInputGroup className='form-group'>
                            <span>Phone Number &nbsp;&nbsp; <i className='fas fa-phone'></i></span>
                            <div style={{display:'flex' ,gap:'10px'}}>
                                <span style={{border:'solid 1px rgba(0,0,0,0.1)' ,padding:'5px 10px' ,backgroundColor:'rgba(0,0,0,0.05)' ,borderRadius:'2px'}} >
                                    +237 694124760
                                </span>
                                {/* <MDBInput value={'venamal.contact@gmail.com'} disabled  /> */}
                            </div>
                            </MDBInputGroup>
                        </div>
                    </div>

                </div>
             
                <div>
                    <h3><FormattedMessage id="help-center" /></h3>
                    <div>
                            <div style={{display:'flex' ,gap:'10px' ,alignItems:'flex-end' ,border:'solid 0px red' ,padding:'10px' ,maxWidth:'600px'}}>
                                <span className='fas fa-user' style={{fontSize:'xx-large'}}></span>    
                                {/* <span> Any doubt, question or problem ? We are Listening ...</span> */}
                                {/* <span><FormattedMessage id="help-1" /> ....</span> */}
                                <span>Nous vous ecoutons ...</span>
                            </div>
                            <div style={{
                                border:'solid 1px rgba(0,0,0,0.2)' ,maxWidth:'600px' ,display:'flex' ,flexDirection:'column' ,gap:'5px' ,
                                alignItems:'flex-end' ,padding:'10px' ,borderRadius:'5px'}}>
                                <MDBInputGroup className='form-group'>
                                    <span>Your Email </span>
                                    <MDBInput type='email' value={info.email} onChange={(e)=>setInfo({...info ,email:e.target.value})} required />
                                </MDBInputGroup>

                                <MDBInputGroup className='form-group'>
                                    <span>Your message </span>
                                    <MDBTextArea value={info.message} onChange={e => setInfo({...info ,message:e.target.value})} required/>
                                </MDBInputGroup>

                                <MDBBtnGroup style={{width:'fit-content' }}>
                                    <MDBBtn color='primary' onClick={()=>!loading && sendMessage()} disabled={loading} ><FormattedMessage id="send" /> &nbsp;
                                    {
                                        !loading ?
                                        <i className='fas fa-paper-plane'></i>
                                        :
                                        <i className='fas fa-clock'></i>
                                    }
                                    </MDBBtn>
                                </MDBBtnGroup>
                            </div>
                            <span style={{color:'darkgreen'}}>{success && "Messaged sent correctly"}</span>
                            <span style={{color:'crimson'}}>{error && "Operation failed"}</span>


                    </div>
                    <br/><br/><br/><br/>
                </div> 
                
            </div>

            <Footer/>
        </React.Fragment>
    )
}

export default ContactInfo