import React,{useState ,useEffect} from 'react'
import {  useParams } from 'react-router-dom'
import { HOST_NAME } from '../../config'
import { MDBBtn, MDBBtnGroup, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent } from 'mdb-react-ui-kit'


const MoneyRecovery = () =>{

    const params = useParams()
    const [phoneNumber ,setPhoneNumber] = useState()
    const [transaction ,setTransaction] = useState({})
    const [loading ,setLoading] = useState(false)
    const [error ,setError] = useState('')

    const [withdrawLoading ,setWithdrawLoading] = useState(false)
    const [withdrawError ,setWithdrawError] = useState('')
    const [withdrawMsg ,setWithdrawMsg] = useState('')

    const [modal ,setModal] = useState(false)

async function getTransaction(){
        setError("")
    setLoading(true)
    try{
        const response = await fetch(`${HOST_NAME}/api/transaction/get-transaction/${params.id}`)
        const data = await response.json()
        console.log(data);
        if(response.ok){
            setTransaction(data.transaction)
        }else{
            setError(data.error || 'A problem occured' )
        }

    }catch(e){
        console.log(e)
        setError("An error occured")
    }finally{
        setLoading(false)
    }
}

    useEffect(()=>{
        getTransaction()
    },[])


    const requestWithdraw=async(e)=>{
        e.preventDefault()
        if(!phoneNumber){console.log('fill');return;}
        setWithdrawError('')
        setWithdrawMsg('')
        setWithdrawLoading(true)
        try{
            const response = await fetch(`${HOST_NAME}/api/transaction/confirm-buyer-withdraw` ,{
                method:"post",
                headers:{
                    'content-type':'application/json'
                },
                body:JSON.stringify({transactionId:params.id ,phoneNumber:phoneNumber})
            })
            const data = await response.json()
            console.log(data)
            if(response.ok){
                setWithdrawMsg(data.msg || "Operation successfull" )
            }else{
                setWithdrawError(data.error || "An error occured")
            }
        }
        catch(e){
            console.log(e)
            setWithdrawError("Please verify your internet")
        }finally{
            // setModal(true)
            setWithdrawLoading(false)
        }
    }

    return(
        <React.Fragment>
            {/* <MDBModal open={modal} onClose={()=>setModal(false)} >
                <MDBModalContent>
                    <MDBModalBody>
                        <span style={{color:'crimson'}} >{withdrawError && withdrawError}</span>
                        <span style={{color:'darkgreen'}} >{withdrawMsg && withdrawMsg}</span>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModal> */}

            <div className='main-container' >
                <div>
                    <h3>&nbsp;  Venamal Recovery System </h3>
                   {
                    // !loading && !error &&
                    <div> <br/>
                        <form onSubmit={e=>requestWithdraw(e)} 
                        style={{maxWidth:'300px',border:'solid 1px rgba(0,0,0,0.2)', padding:'20px 10px' ,borderRadius:'5px' ,margin:'auto'}}>
                            <MDBInputGroup className='form-group' >
                                <span>Receiver phone Number</span>
                                <MDBInput value={phoneNumber} onChange={e=>setPhoneNumber(e.target.value)} type="number" />
                            </MDBInputGroup>
                            <br/>

                            <span style={{color:'crimson'}} >{withdrawError && withdrawError}</span>
                            <span style={{color:'darkgreen'}} >{withdrawMsg && withdrawMsg}</span>

                            <MDBBtnGroup  >
                                <MDBBtn  style={{width:'fit-content'}}  disabled={withdrawLoading} >Request withdraw</MDBBtn>
                            </MDBBtnGroup>

                        </form>
                    </div>}
                    {error && <div style={{color:'crimson' ,textAlign:'center' ,padding:'10px 0px'}} >{error}</div>}

                   
                </div>
            </div>

        </React.Fragment>
    )
}


export default MoneyRecovery