import React from 'react'

const NetworkCard = () => {
    return(
        <React.Fragment>
            <div style={{
                position:'fixed' ,bottom:'20px' ,border:'solid 1px rgba(0,0,0,0.2)' ,padding:'5px 10px',borderColor:'crimson',
                left:'50%' ,transform:'translateX(-50%)' ,backgroundColor:'rgb(25,25,25 ,0.85)' ,borderRadius:'5px'
            }} >
                <span style={{color:'whitesmoke'}} >Poor connection <i className='fas fa-wifi' style={{textDecoration:'line-through'}} ></i> </span>
            </div>
        </React.Fragment>
    )
}


export default NetworkCard