import React ,{useLayoutEffect, useState} from 'react'
import { useNavigate ,useLocation } from 'react-router-dom'
import { MDBInput ,MDBInputGroup ,MDBBtn ,MDBBtnGroup } from 'mdb-react-ui-kit'

import '../../assets/styles/auths-styles.css'
import { HOST_NAME } from '../../config'

const UpdateStore = () =>{
    const navigate = useNavigate()
    const location = useLocation()

    const [loading ,setLoading] = useState(false)
    const [error ,setError] = useState('')
    const [data ,setData] = useState({
        name:'',
        phone1:"",
        phone2:'',
        email:'',
        location:"",
        region:""
    })

    useLayoutEffect(()=>{
        if(location.state && location.state.store){
            let store = location.state.store
            let contact = store.contact[0].split('-')
            // console.log(store)
            setData({
                _id:store._id,
                name:store.name,
                location:store.location[0],
                email:contact[0],
                phone1:contact[1],
                phone2:contact.length == 3 ? contact[2]:undefined,
                region:store.region
            })

            // console.log(location.state.store)
        }else{
            window.location.pathname = "/"
        }
    },[0])

// console.log(data)

    async function updateStore(){
        let {name ,email ,phone1 ,phone2 ,location ,region} = data
        if(!name || !email || !phone1 || !location || !region){
            setError("Please fill the entire fields")
            return
        }
        const user = await JSON.parse(localStorage.getItem("venamal-user"))

        try{
            setError('')
            setLoading(true)
                let body ={
                    name:data.name,
                    location:data.location,
                    // userId:user.id,
                    contact:data.email+"-"+data.phone1+"-"+data.phone2,
                    id:data._id,
                    region:region
                }
            const url = `${HOST_NAME}/api/store/update-store`
            const response = await fetch(url ,{
                method:'put',
                headers:{
                    'content-type':'application/json',
                    'authorization':`Bearer ${user.token}`
                },
                body:JSON.stringify(body)
            })
            const result = await response.json()
                // console.log(result)
                if(response.ok){
                let newUser = user
                    newUser.store = {...user.store ,name:body.name ,location:[body.location] ,contact:[body.contact] ,region:body.region}
                    localStorage.setItem("venamal-user" ,JSON.stringify(newUser))
                    window.location.pathname = "/store"
                    // navigate("/store")
            }else{
                setError(result.error)
            }

        }
        catch(e){
            // console.log(e)
            setError("Please verify your internet ")
        }
        finally{
            setLoading(false)
        }
    }

    return(
        <React.Fragment>
            <div className='auth-container' style={{marginTop:'15px' }}>
                    <h3> <span style={{fontSize:'small' ,fontWeight:'bold' ,cursor:'pointer'}} onClick={()=>navigate("/store")}><i className='fas fa-arrow-left'></i> back <br/> </span>
                     Update Store Informations</h3>
                <div className='form'>

                    <MDBInputGroup className='form-group'>
                        <span style={{fontWeight:'bold'}}>Store Name</span>
                        <MDBInput 
                            value={data.name}
                            type="text"
                            onChange={e=>setData({...data ,name:e.target.value})}
                        />
                    </MDBInputGroup>

                    <MDBInputGroup className='form-group'>
                        <span style={{fontWeight:'bold'}} >Contact Informations</span>
                        <MDBInputGroup>
                            <MDBInputGroup className='form-group'>
                                <span>Phone 1 <i style={{fontSize:'small'}}>(Whatsapp)</i> </span>
                                <MDBInput 
                                    value={data.phone1}
                                    type="number"
                                    onChange={(e)=>setData({...data ,phone1:e.target.value})}
                                />
                            </MDBInputGroup>

                            <MDBInputGroup className='form-group'>
                                <span>Phone 2<i style={{fontSize:'small'}}>(optional)</i></span>
                                <MDBInput 
                                    value={data.phone2}
                                    type="number"
                                    onChange={e=>setData({...data ,phone2:e.target.value})}
                                />
                            </MDBInputGroup>

                        </MDBInputGroup>

                        <MDBInputGroup className='form-group'>
                            <span>Email</span>
                            <MDBInput 
                                value={data.email}
                                type="email"
                                onChange={e=>setData({...data ,email:e.target.value})}
                            />
                        </MDBInputGroup>
                    </MDBInputGroup>


                    <MDBInputGroup style={{ alignItems:'center' ,gap:'20px'}}>
                        <span  style={{fontWeight:'bold'}}>Region<i style={{fontSize:'small' ,fontWeight:'lighter'}}>
                        </i></span>

                        <select  style={{padding:'5px'}} onChange={(e)=>setData({...data ,region:e.target.value})} value={data.region}>
                            <option value={null} >---------</option>
                            <option value={'Far North'}>Far North</option>
                            <option value={'North'}>North</option>
                            <option value={'Ngaoundere'}>Ngaoundere</option>
                            <option value={'Centre'}>Centre</option>
                            <option value={'Littoral'}>Littoral</option>
                            <option value={'West'}>West</option>
                            <option value={'South West'}>South West</option>
                            <option value={'North West'}>North West</option>
                            <option value={'East'}>East</option>
                            <option value={'South'}>South</option>

                        </select>
                    </MDBInputGroup>

                    <MDBInputGroup className='form-group'>
                        <span  style={{fontWeight:'bold'}}>Location<i style={{fontSize:'small' ,fontWeight:'lighter'}}>(for more than one seperate with , )</i></span>
                        <MDBInput 
                            value={data.location}
                            type="text"
                            onChange={e=>setData({...data ,location:e.target.value})}

                        />
                    </MDBInputGroup>

                    <span style={{color:'crimson' ,textAlign:'center' ,fontSize:'small' }}>{ error }</span>

                    <MDBBtnGroup onClick={()=>updateStore()}>
                        <MDBBtn color='success'>Update STORE <i className='fas fa-store'></i>
                            {loading ? "...":""}
                         </MDBBtn>
                    </MDBBtnGroup>

                </div>

            </div><br/><br/>
        </React.Fragment>
    )
}

export default UpdateStore