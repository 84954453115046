import React ,{useEffect ,useState} from "react";
import {MDBBtn} from 'mdb-react-ui-kit'
import {useNavigate} from 'react-router-dom'

const Thanks = () => {
    
    const [canView ,setCanView] = useState(false)
    const navigate = useNavigate()

    useEffect(()=>{
        function verifyPrevious(){
            setCanView(true)
        }
        verifyPrevious()
    } ,[0])

    if(canView){
        return(
            <React.Fragment>
                <div style={{backgroundColor:'rgba(0,0,0,0.9)' ,width:'100%' ,height:'100%' ,position:"absolute"}} >
                    
                    <div style={{backgroundColor:'whitesmoke' ,border:'solid 1px grey'  ,minWidth:'300px' ,width:'fit-content' ,position:'absolute' ,left:'50%' ,top:'40%' ,transform:'translate(-50% ,-50%)' ,padding:'25px'}} >
                        <h2>Thanks for using VENAMAL!</h2>
                        <p style={{textAlign:'left'}} >
                            <strong>Venamal</strong> thanks you for your purchase<br/>
                            To view more detail about your transaction history:<br/>
                            <MDBBtn onClick={()=>navigate('/history')} > History <i className="fas fa-clock"></i> </MDBBtn>.<br/>
                            Best Regards.
                        </p>
                    </div>
                </div>
    
            </React.Fragment>
        )
    }
}

export default Thanks