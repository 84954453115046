import React ,{useContext, useState} from 'react'
import { HOST_NAME } from '../config'

import FilterContext from '../providers/FilterProvider'
import ProductsContext from '../providers/ProductProvider'
import NetworkCard from './modals&cards/NetworkCard'

const Location =({setSearched}) =>{

    const filterContext = useContext(FilterContext)
    const productsContext = useContext(ProductsContext)

    const [loading ,setLoading] = useState(false)
    const [error ,setError] = useState(false)
    const [activeRegion ,setActiveRegion] = useState('')
    const regions = ['Far North' ,'North' ,'Ngaoundere' ,'Centre' ,'Littoral' ,'West' ,'South West' ,'North West' ,'East' ,'South']


const regionFilter =async(region) =>{

    let category = filterContext.category
    let url
    if(activeRegion == region){
        filterContext.setRegion(null)
        setActiveRegion('')
        if(category){
            url = `${HOST_NAME}/api/item/get-filtered-items?category=${category}`
        }else{
            url = `${HOST_NAME}/api/item/get-items`
        }
    }else{
        filterContext.setRegion(region)
        setActiveRegion(region)
        if(category){
            url = `${HOST_NAME}/api/item/get-filtered-items?category=${category}&region=${region}`
        }else{
            url = `${HOST_NAME}/api/item/get-filtered-items?region=${region}`
        }
    }

        setLoading(true)
    try{
        // const response = await fetch(`${HOST_NAME}/api/item/get-item-by-region/${region}`)
        const response = await fetch(url)
        const data = await response.json()
        
        if(response.ok){
            // console.log(data.items);
            productsContext.setProducts(data.items)
            setSearched(prev => !prev)
        }else{
            setError(true)
            setTimeout(()=>{
                setError(false)
            } ,3000)
        }
    }
    catch(e){
        console.log(e)
        setActiveRegion('')
        setError(true)
        setTimeout(()=>{
            setError(false)
        } ,3000)
    }
    finally{
        setLoading(false)
    }
}

    return(
        <React.Fragment>

            {error && <NetworkCard/>}

            <div>
            <span style={{paddingLeft:'10px' ,marginBottom:'0px' ,fontSize:'x-large' ,fontFamily:'arial'}} id="items">
                Localisation    
            </span>

                <div style={{padding:'0px 10px',border:'solid 0px rgba(0,0,0,0.2)' ,display:'flex' ,flexWrap:'wrap' ,justifyContent:'space-between' ,gap:'0px' }}>
                    {regions.map((region ,index) => (
                    <LocationItem 
                        key={index} 
                        region={region} 
                        regionFilter={regionFilter}
                        activeRegion={activeRegion}
                        loading={loading}
                    />
                    ))}
                </div>

            </div>
        </React.Fragment>
    )
}


export default Location

const LocationItem =({region ,regionFilter ,activeRegion ,loading})=>{
    return(<React.Fragment>
        <div 
        onClick={()=>regionFilter(region)}
        style={{
            border:'solid 1px rgba(0,0,0,0.1)' ,padding:'5px 10px' ,width:'50%' ,cursor:'pointer',
            color:activeRegion == region ? 'white':'' ,backgroundColor:activeRegion == region ? 'grey':'' 
        }}>
            <span> {region} { activeRegion == region && loading && '...'} </span>
        </div>
    </React.Fragment>)
}