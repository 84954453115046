import React ,{useState ,useEffect ,useContext ,useLayoutEffect} from 'react'
import ProductItem from './ProductItem'
import { HOST_NAME } from '../config'
import { FormattedMessage } from 'react-intl'

import '../assets/styles/product-styles.css'

import ProductsContext from '../providers/ProductProvider'
import { useNavigate , useParams ,useSearchParams} from 'react-router-dom'

const Products = ({searched})=>{

    const productsContext = useContext(ProductsContext)

    const [products ,setProducts] = useState([])
    const [loading ,setLoading] = useState(false)
    const [error ,setError] = useState('')

    const [more ,setMore] = useState(true)
    const [fetching ,setFetching] = useState(false)
    const [fetchError ,setFetchError] = useState('')

    const [total ,setTotal] = useState()
    const [target ,setTarget] = useState(1)

    const [fastDeal ,setFastDeal] = useState([])
    const [params ,setParams] = useSearchParams()
    // let page = useParams()
    // console.log("he params ",page)
// Getting the inital products 
useLayoutEffect(()=>{
    async function getProducts(){
        const url = `${HOST_NAME}/api/item/get-items`
        setLoading(true)
        setError('')
        try{
            const response = await fetch(url ,{
                method:"get",
                headers:{
                    'content-type':'application/json',
                    'accept':'application/json',
                    'access-control-origin':'*'
                },
            }) 
            const data = await response.json()
            // console.log(data)
            
            if(response.ok){
                setProducts(data.items)
                setTotal(data.total)
                productsContext.setProducts(data.items)
                productsContext.setTotal(data.total)

            }else{
                setError(data.error)
            }
        }
        catch(e){
            // console.log(e)
            
            setError(<FormattedMessage id="no-internet" />)
        }
        finally{
            setLoading(false)
        }
    }
    if(productsContext.products.length == 0){
        getProducts()
        // console.log("context empty")
    }else{
        // console.log("context not empty")
        setTotal(productsContext.total)
        setProducts(productsContext.products)
        setTarget(productsContext.target)
    }
},[0])
// console.log(target)

// Getting fast deals items
// console.log(fastDeal)
// useEffect(()=>{
//     async function getFastDeals(){
//         try{
//             const r = await fetch(`${HOST_NAME}/api/item/get-fastDeals`)
//             const d = await r.json()
//             if(r.ok){
//                 setFastDeal(d.items)
//             }
//         }
//         catch(e){
//             console.log(e)
//         }
//     }
//     getFastDeals()
// } ,[0])


useEffect(()=>{
    // if(productsContext.products && productsContext.products.length > 0){
        setProducts(productsContext.products)
    // }
},[searched])

const MemoriseProducts = React.memo(ProductItem)

let items =[]
if(products && products.length > 0){
    items = products.map((product ,index) => {
        // return <ProductItem item={product} index={index} key={product._id} />
        return <MemoriseProducts item={product} index={index} key={product._id} />

    })
}

// Getting more items
const fetchMore = async()=>{
    setFetching(true)
    setFetchError('')
    // console.log("entering here")
    try{
        let length = products.length
        const url = `${HOST_NAME}/api/item/get-items/${target-1}`
        if(more){
    // console.log("but not entering here")

            const response = await fetch(url ,{
                headers:{
                    'content-type':'application/json'
                }
            })
            const data = await response.json()
            // console.log("data recieved after a fetch again" ,data)
            if(response.ok){
                if(data.items.length > 0){
                    let temp
                    for(let i=0;i<products.length;i++){
                        temp = data.items.filter(item => item._id != products[i]._id)
                    }
                    // setProducts([...products ,...temp])
                    // productsContext.setProducts([...products ,...temp])   

                    setProducts(data.items)
                    productsContext.setProducts(data.items)  
                    // productsContext.setTarget(target) 
                    if(data.items.length < 10){
                        // setMore(false)
                    }
                }
            }else{
                setFetchError(data.error)
            }
        }
    }
    catch(e){
        // console.log(e)
        setFetchError("Please verify your internet !")
    }
    finally{
        setFetching(false)
    }
}
// Fecthing more after the change of the target
useEffect(()=>{
    // console.log('fetching again at' ,target)
    // window.location.pathname = window.location.pathname+`?page=${target}`
    // setParams({page:1})

    fetchMore()
},[target])


    return (
        <React.Fragment>
            <div>
                {/* {
                fastDeal.length != 0 &&

                 <div style={{margin:'10px 0px'}}>
                    <h3 style={{paddingLeft:'10px' ,marginBottom:'0px' }} id="items">
                        Fast deals <span style={{fontWeight:'bold' ,color:'crimson'}}>(-20% <i className='fas fa-running'></i>)</span> 
                    </h3>

                    <div style={{overflow:'hidden' ,height:'fit-content' ,display:'flex' ,width:'95%' ,border:'solid 0px red',margin:'auto'}}>
                        <div style={{display:'flex' ,overflowX:'auto' ,padding:'10px' ,gap:'15px'}}>

                            {fastDeal.map(deal => <FastDeal item={deal} key={deal._id}/>)}

                            </div>
                        </div>
                    </div> 
                } */}
     

                <div >

                    <span style={{paddingLeft:'10px' ,marginBottom:'0px' ,fontSize:'x-large' ,fontFamily:'arial'}} id="items"><FormattedMessage id="items" /> 
                    {/* <span style={{fontSize:'medium'}}> (<i className='fas fa-shield-alt' style={{color:'rgba(0,0,255,0.7)' }}></i> official Venamal products)</span>  */}
                    </span>
                    <div className='product-container'>
                        {loading ? <span style={{ minHeight:'20vh',fontSize:'large' ,letterSpacing:'1px'}}>Loading products ...</span>:items}
                        <div style={{fontSize:'large' ,letterSpacing:'1px' ,color:'crimson'}}>{error && error}</div>
                        {items.length == 0 && !error && !loading && <span style={{fontSize:'large' ,letterSpacing:'1px'}}>No product</span> }

                    </div>
                        {/* The show more option */}
                        {/* { !loading && !error && products.length != 0 && products.length > 10 && <Pagination total={total} target={target} setTarget={setTarget} />} */}
                        {/* {
                            more && !loading && !error && products.length > 9 &&
                            <div style={{margin:'auto' ,border:'solid 0px red' ,display:'flex' ,flexDirection:'column' ,alignItems:'center'}}
                             onClick={()=>{}}>
                                {
                                    !fetching ?
                                    <span style={{cursor:'pointer' ,color:'darkblue' ,margin:'30px 0px'}}>
                                            the place
                                    </span>
                                    :
                                    <span>Loading ...</span>
                                }
                                    {
                                        fetchError && 
                                        <span>{fetchError}</span>
                                    }
                            </div>

                        } */}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Products


const FastDeal=({item})=>{

    const navigate = useNavigate()

    return(
        <React.Fragment>
        <div onClick={()=>navigate("/detail" ,{state:item})} style={{cursor:'pointer'}}>
            <img
                src={`${item.images[0]}`}
                width={167}
                height={175}
                style={{objectFit:'cover' ,border:'solid 1px rgba(0,0,0,0.3)' ,borderTopLeftRadius:'15px' ,
                borderBottomRightRadius:'15px'
            }}
            />

            <div style={{display:'flex' ,flexDirection:'column' ,padding:'5px 0px' ,border:'' ,
                borderBottom:'0px' ,
                }}>
                    <span style={{ color:'' ,fontWeight:'bold'}}>
                        <span style={{textDecoration:'line-through' ,color:'rgba(200,1,1,0.5)'}}>{item.price/0.8}CFA</span> &nbsp;{item.price} CFA
                        </span> 
                </div>

        </div>
        </React.Fragment>
    )
}


const Pagination=React.memo(({total ,target ,setTarget})=>{

let length = []
for(let i=0;i<Math.ceil(total/10);i++){
    length.push(i)
}

function changePage(direction){
    // console.log("the target" ,target)
    // console.log("the the end" ,Math.ceil(total/10))

    if(direction > 0){
        if(target == Math.ceil(total/10)){
            return
        }
    }else{
        if(target == 1){
            return
        }
    }
    setTarget(target+direction)
    // window.scrollTo({top:500})
}

    return(
        <div className='pagination'>
            <span className='left' 
                onClick={()=>changePage(-1)}
                title="previous"
            ><i className='fas fa-chevron-left'></i></span>

            {length.map((l ,index) => (
            <span key={index}
            style={{
                width:'25px' ,height:'30px',display:'flex' ,alignItems:'center' ,justifyContent:'center',
                color:target-1 == l && 'darkblue' ,borderBottom:target-1 == l && 'solid 2px darkblue'
            }}
                title={l+1}
                onClick={()=>setTarget(l+1)}
            >
            {l+1}</span>))}
            
            <span className='right'
                onClick={()=>changePage(1)}
                title="next"
            ><i className='fas fa-chevron-right'></i></span>   
            <br/><br/><br/><br/><br/>        
        </div>
    )
})