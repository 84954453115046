import React ,{ createContext ,useState ,useEffect} from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

const FilterContext = createContext({
    region:null,
    category:null,
    setRegion:null,
    setCategory:null
})

export const FilterProvider = ({children}) => {

    const [category ,setCategory] = useState(null)
    const [region ,setRegion] = useState(null)


    return <FilterContext.Provider value={{region:region ,category:category ,setRegion:setRegion ,setCategory:setCategory}} >{children}</FilterContext.Provider>
}

export default FilterContext