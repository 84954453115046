import React ,{useLayoutEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { MDBInput ,MDBInputGroup ,MDBBtn ,MDBBtnGroup } from 'mdb-react-ui-kit'

import '../../assets/styles/auths-styles.css'
import { HOST_NAME } from '../../config'

const CreateStore = () =>{
    const navigate = useNavigate()

    const [loading ,setLoading] = useState(false)
    const [error ,setError] = useState('')
    const [data ,setData] = useState({
        name:'',
        phone1:"",
        phone2:'',
        email:'',
        location:"",
        region:""
    })

useLayoutEffect(()=>{
    async function verifyStore(){
        const user = await JSON.parse(localStorage.getItem("venamal-user"))
            console.log(user)
            if(user.store){
                navigate("/store")
            }
    }
        verifyStore()
},[0])

    async function createStore(){
        let {name ,email ,phone1 ,phone2 ,location, region} = data
        if(!name || !email || !phone1 || !location || !region){
            setError("Please fill the entire fields")
            return
        }
        const user = await JSON.parse(localStorage.getItem("venamal-user"))

        try{
            setError('')
            setLoading(true)
                let body ={
                    name:data.name,
                    location:data.location,
                    userId:user.id,
                    contact:data.email+"-"+data.phone1+"-"+data.phone2,
                    region:data.region
                }
            const url = `${HOST_NAME}/api/store/create-store`
            const response = await fetch(url ,{
                method:'post',
                headers:{
                    'content-type':'application/json'
                },
                body:JSON.stringify(body)
            })
            const result = await response.json()
                // console.log(result)
                if(response.ok){
                // return
                let newUser = user
                // console.log(newUser)
                    newUser.store = result.store
                    localStorage.setItem("venamal-user" ,JSON.stringify(newUser))
                // return
                navigate("/store")
            }else{
                setError(result.error)
            }

        }
        catch(e){
            // console.log(e)
            setError("Please verify your internet ")
        }
        finally{
            setLoading(false)
        }
    }

    return(
        <React.Fragment>
            <div className='auth-container'>
            <span style={{fontWeight:'bold' ,cursor:'pointer'}} onClick={()=>navigate("/")}><i className='fas fa-arrow-left'></i> back <br/> </span>
                    <h3>Store Creation Form</h3>
                <div className='form'>

                    <MDBInputGroup className='form-group'>
                        <span style={{fontWeight:'bold'}}>Store Name</span>
                        <MDBInput 
                            value={data.name}
                            type="text"
                            onChange={e=>setData({...data ,name:e.target.value})}
                        />
                    </MDBInputGroup>

                    <MDBInputGroup className='form-group'>
                        <span style={{fontWeight:'bold'}} >Contact Informations</span>
                        <MDBInputGroup>
                            <MDBInputGroup className='form-group'>
                                <span>Phone 1 <i style={{fontSize:'small'}}>(Whatsapp)</i> </span>
                                <MDBInput 
                                    value={data.phone1}
                                    type="number"
                                    onChange={(e)=>setData({...data ,phone1:e.target.value})}
                                />
                            </MDBInputGroup>

                            <MDBInputGroup className='form-group'>
                                <span>Phone 2<i style={{fontSize:'small'}}>(optional)</i></span>
                                <MDBInput 
                                    value={data.phone2}
                                    type="number"
                                    onChange={e=>setData({...data ,phone2:e.target.value})}
                                />
                            </MDBInputGroup>

                        </MDBInputGroup>

                        <MDBInputGroup className='form-group'>
                            <span>Email</span>
                            <MDBInput 
                                value={data.email}
                                type="email"
                                onChange={e=>setData({...data ,email:e.target.value})}
                            />
                        </MDBInputGroup>
                    </MDBInputGroup>

                    {/* <MDBInputGroup className=''> */}
                        <MDBInputGroup style={{justifyContent:'flex-start',gap:"50px" ,alignItems:'center'}}>
                            <span  style={{fontWeight:'bold'}}>Region</span>
                        <select required style={{padding:'5px'}} onChange={(e)=>setData({...data ,region:e.target.value})} value={data.region}>
                            <option value={null} >---------</option>
                            <option value={'Far North'}>Far North</option>
                            <option value={'North'}>North</option>
                            <option value={'Ngaoundere'}>Ngaoundere</option>
                            <option value={'Centre'}>Centre</option>
                            <option value={'Littoral'}>Littoral</option>
                            <option value={'West'}>West</option>
                            <option value={'South West'}>South West</option>
                            <option value={'North West'}>North West</option>
                            <option value={'East'}>East</option>
                            <option value={'South'}>South</option>
                        </select>
                        </MDBInputGroup>

                        <MDBInputGroup className='form-group' >
                            <span  style={{fontWeight:'bold'}}>Location<i style={{fontSize:'small' ,fontWeight:'lighter'}}>
                                (for more than one seperate with , )
                            </i></span>
                                <MDBInput 
                                    value={data.location}
                                    type="text"
                                    onChange={e=>setData({...data ,location:e.target.value})}
                                />
                        </MDBInputGroup>
                    {/* </MDBInputGroup> */}

                    <span style={{color:'crimson' ,textAlign:'center' ,fontSize:'small' }}>{ error }</span>

                    <MDBBtnGroup onClick={()=>createStore()}>
                        <MDBBtn color='success'>Create STORE <i className='fas fa-store'></i>
                            {loading ? "...":""}
                         </MDBBtn>
                    </MDBBtnGroup>

                </div>

            </div>
        </React.Fragment>
    )
}

export default CreateStore