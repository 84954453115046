import React ,{useState ,useEffect ,useMemo ,useRef, useCallback} from 'react'
import {HOST_NAME} from '../config'
import { useNavigate ,useSearchParams ,createSearchParams  } from 'react-router-dom'
import {
    MDBModalBody,MDBModalContent,MDBModalTitle, MDBBtnGroup, MDBBtn, MDBModalHeader ,
    MDBModal ,MDBModalFooter
} from 'mdb-react-ui-kit'


const ProductItem=React.memo(({item ,owner ,index ,deleteItem ,deleting ,deleteError ,view})=>{

    const navigate = useNavigate()
    const [searchParams ,setSearchParams] = useSearchParams()
    
    const [deleteModal ,setDeleteModal] = useState(false)
    
    
const openDetail=()=>{
    if(!owner){
        if(!view){
                let newSearch = createSearchParams({item:item._id})
            navigate("/detail?"+newSearch )

        }
    }
}
const placeholder = require('../assets/images/placeholder-image.png')

// console.log(((new Date()).getDay() + 14) % 7 )
// console.log("the product ")
let shortName 
if(item.name.length > 20){
    shortName = item.name.slice(0 ,17) + "..."
}else{shortName = item.name}

const displayStars = useCallback((num) => {
    let stars = []
    if(num == 0){return <i className='fas fa-star' ></i>}
    for(let i=0;i<num;i++){
        stars.push(<i className='fas fa-star' ></i>)
    }
return stars
},[0])


    return(
        <React.Fragment>

            <MDBModal 
                open={deleteModal }
                onClose={()=>setDeleteModal(false)}
                
            >
            <MDBModalContent style={{top:'20%' ,width:'300px' ,margin:'auto'}}>
                <MDBModalHeader>
                    <MDBModalTitle>Delete Item <i className='fas fa-trash'></i> </MDBModalTitle>
                </MDBModalHeader>
                <MDBModalBody style={{textAlign:'center'}}>
                    Are you sure you want to <span style={{color:'red'}}>DELETE</span> <span style={{fontWeight:'bold'}}>{item.name} ?</span> 
                </MDBModalBody>
                    <span style={{textAlign:'center' ,color:'crimson'}}>{deleteError && deleteError}</span>
                <MDBModalFooter>
                    {/* <MDBBtnGroup> */}
                        <MDBBtn color="primary" onClick={()=>setDeleteModal(false)}>Cancel</MDBBtn>
                        <MDBBtn color="danger" onClick={()=>deleteItem(item)}>Delete{deleting ? '...':''}</MDBBtn>

                    {/* </MDBBtnGroup> */}
                </MDBModalFooter>

            </MDBModalContent>

            </MDBModal>

            <div 
                className='product-item'
                onClick={()=>openDetail()}
            >

                    {/* New band */}
                {((new Date()).getMonth() + 1) == item.createdAt.split("T")[0].split("-")[1] ?
                <span style={{position:'absolute' ,border:'solid 1px red' ,
                borderTopLeftRadius:'15px' , background:'red' ,color:'white',
                fontSize:'small' ,borderBottomRightRadius:'5px' ,padding:'1px'}}>new</span>
                :''
                }

                <div 
                className='product-image-container'
                style={{ backgroundImage:`url(${placeholder})` }}>
                    <img
                        src={ `${item.images[0]}`}
                        className='product-image'
                    />
                </div>
                <div 
                    style={{border:owner ? 'solid 0px rgba(0 0 0 0.1)':'solid 0px rgba(0 0 0 0.2)' }}
                    className='product-description'
                >
                    {/* <span style={{display:'flex' ,alignItems:'center'}}>
                        <span>{shortName.charAt(0).toUpperCase() + shortName.slice(1)} </span>&nbsp;
                     </span> */}
                    <span style={{fontSize:'large' ,color:'black' ,fontFamily:'monospace' ,fontWeight:'bold'}}>{item.price} CFA</span>
                    <div style={{fontSize:'small' ,fontWeight:'' ,display:'flex' ,alignItems:'center'}} >
                        <span> <i className='fas fa-store' style={{fontSize:'x-small'}} ></i> {item.store.name}  </span>
                        <span style={{fontSize:'x-small'}}> &nbsp;&nbsp;-&nbsp;&nbsp; {displayStars(item.store.rating || 0)} </span>
                    </div>
                    <span style={{fontSize:'small'}} >CMR - {item.store.region} <i className='fas fa-map-marker-alt' ></i> </span>

                </div>
                {
                    owner &&
                    <div style={{display:'flex' ,justifyContent:'space-around' ,border:'solid 1px rgba(0,0,0,0.1)' ,borderTop:'0px' ,paddingBottom:'5px'}}>
                        <span style={{color:'' ,cursor:'pointer'}} onClick={()=>navigate("/store/manage-product" ,{state:{item:item}})} >
                            <i className='fas fa-pen' style={{fontWeight:'bolder' ,color:'darkblue'}}></i> Update
                        </span>
                        
                        <span style={{color:'' ,cursor:'pointer'}} onClick={()=>setDeleteModal(true)} >
                            <i className='fas fa-trash' style={{fontWeight:'bolder' ,color:'darkred'}}></i> Delete
                        </span>
                        
                    </div>
                }
            </div>
        </React.Fragment>
    )
} ,(prev ,next) => true)

export default ProductItem