import React,{useEffect ,useState ,useMemo} from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { HOST_NAME } from '../../config'
import {MDBTable ,MDBTableBody ,MDBTableHead ,MDBRow ,MDBCol,
MDBModal ,MDBModalContent ,MDBModalBody ,MDBModalHeader ,MDBModalTitle,
MDBInput ,MDBInputGroup ,MDBBtn ,MDBBtnGroup} from 'mdb-react-ui-kit'

const Transactions = () =>  {

    const user = useOutletContext()
    const navigate = useNavigate()
    const [transactions ,setTransactions] = useState([])
    const [loading ,setLoading] = useState(false)
    const [error ,setError] = useState('')

    const [modal ,setModal] =  useState(false)
    const [target ,setTarget] = useState()

    const [withdrawModal ,setWithdrawModal] = useState(false)
    const [withdrawLoading ,setWithdrawLoading] = useState(false)
    const [withdraw ,setWithdraw] = useState({code:'' ,phoneNumber:'' })
    const [withdrawError ,setWithdrawError] = useState('')
    const [withdrawMsg ,setWithdrawMsg] = useState('')

    const [message ,setMessage] = useState('')
    const [merror ,setMerror] = useState('')
    const [mloading ,setMloading] = useState(false)
    const [mm ,setMm] = useState(false)



    // Function Getting the token
    const getInfo = useMemo(async()=>{
        const user = await JSON.parse(localStorage.getItem('venamal-user'))
        return user.token

    })

    // console.log(user)

    // Getting the various transactions
    useEffect(()=>{
        async function fetchTransactions(){
            const token = await getInfo
            // console.log(token)
            setError('')
            try{
                if(token){
                    setLoading(true)
                    const url = `${HOST_NAME}/api/transaction/get-store-transaction/${user.store._id}`
                    fetch(url ,{
                        headers:{
                            'content-type':'application/json',
                            'authorization':`Bearer ${token}`
                        }
                    }).then(res => res.json())
                    .then(data => {
                        console.log(data)
                        if(data.error){
                            setError(data.error)
                        }else{
                            setTransactions(data.transactions)
                        }
                    })
                    .catch(e => {
                        // console.log(e)
                        setError('Verify your internet connection')
                    })
                    .finally(()=>setLoading(false))

                }else{
                    setError('Invalid token')
                }
            }
            catch(e){
                // console.log(e)
                // setError('Verify your internet connection')
            }
        } 
        fetchTransactions()
    },[0])




// Function too delete a transaction
async function deleteTransaction(id){
    try{
        const token = await getInfo
        const response = await fetch(`${HOST_NAME}/api/transaction/hide-transaction`, {
            method:'post',
            headers:{
                'authorization':`Bearer ${token}`,
                'content-type':'application/json'
            },
            body:JSON.stringify({id:id})
        })
        if(response.ok){
            // const data= await response.json()
            setTransactions(prev => {
                let temp = prev.filter(transaction => transaction._id != id)
                return temp
            })

        }
    }   
    catch(e){

    }
}


// Function to display modal to view more information
const viewMore=(transaction)=>{
    setTarget(transaction)
    setModal(true)
}


// Function to withdraw money from a transaction
async function withDrawMoney(e){
    e.preventDefault()

    setWithdrawLoading(true)
    setWithdrawError('')
    setWithdrawMsg('')
    try{
        const token = await getInfo
        const response = await fetch(`${HOST_NAME}/api/transaction/confirm-seller-withdraw` ,{
            method:'post',
            headers:{
                'content-type':'application/json',
                'authorization':`Bearer ${token}`
            },
            body:JSON.stringify({code:withdraw.code ,phoneNumber:withdraw.phoneNumber,transactionId:withdrawModal._id} )
        })
        const data = await response.json()
        console.log(data)
        if(response.ok){
            setWithdrawMsg(data.msg)
        }else{
            setWithdrawError(data.error || "An error occured")
        }
    }
    catch(e){
        console.log(e)
        setWithdrawError("Verify your internet connection")
    }
    finally{
        setWithdrawLoading(false)
    }
}


let displayTransactions
if(transactions){
    displayTransactions = transactions.map(transaction => {
        return (
        <Record 
            transaction={transaction} 
            key={transaction.reference} 
            deleteTransaction={deleteTransaction} 
            viewMore={viewMore} 
            setWithdrawModal={setWithdrawModal}
        />)
    })
}

async function sendMessage(email ,phoneNumber){
    if(message != ''){
        try{
            setMm(false)
            setMloading(true)
            setMerror('')
            const response = await fetch(`${HOST_NAME}/api/transaction/send-message` ,{
                method:'post',
                headers:{
                    'content-type':'application/json',
                    'authorization':`Bearer ${await getInfo}`
                },
                body:JSON.stringify({phoneNumber:phoneNumber ,email:email ,message:message})
            })
            const data = await response.json()
            if(response.ok){
                setMm(true)
                setMessage('')
            }else{
                setMerror(data.error)
            }
        }
        catch(e){
            // console.log(e)
            setMerror('Verify Internet')
        }finally{
            setMloading(false)
        }

    }
}

function colorCode(status){
    switch(status){
        case 'pending':
            return 'orange';
        case 'failed':
            return 'red';
        case 'success':
            return 'green'
    }
}


// console.log(transactions)
const targetTransaction = withdrawModal
    return(
        <React.Fragment>
            {/* Modal */}
            {
                target &&
            <MDBModal open={modal} onClose={()=>setModal(false)} >
                <MDBModalContent 
                style={{width:'fit-content' ,left:'45%' ,top:'10%' ,transform:'translate(-45%)' ,margin:'0px 5px'}}>
                    <MDBModalHeader>
                        <MDBModalTitle>Transaction Information</MDBModalTitle>
                    </MDBModalHeader>
                    <MDBModalBody >
                        <MDBTable bordered responsive striped style={{border:'solid 1px grey'}}>
                                <MDBTableHead  style={{color:'white' ,backgroundColor:'rgba(0 ,100 ,50)' ,borderBottom:'solid 1px grey'}}>
                                    <MDBRow >
                                        <MDBCol center>Name</MDBCol>
                                        <MDBCol center>Price </MDBCol>
                                        {/* <MDBCol center>Quantity</MDBCol> */}
                                        <MDBCol center>Date</MDBCol>
                                        <MDBCol center>Duration</MDBCol>

                                    </MDBRow>

                                </MDBTableHead>

                                <MDBTableBody>
                                {target.items.map( (t ,index) => {
                                    // console.log("the item in the modal : "+target)
                                    if(t.id){
                                        return (
                                            <MDBRow key={target._id}>
                                                <MDBCol center >{t.id.name}</MDBCol>
                                                <MDBCol center >{t.id.price}</MDBCol>
                                                {/* <MDBCol center >{t.quantity}</MDBCol> */}
                                                <MDBCol center >{target.createdAt.split('T')[0]}</MDBCol>
                                                <MDBCol center >{t.id.duration}</MDBCol>
    
                                            </MDBRow>
                                        )
                                    }else{
                                        return (
                                            <MDBRow key={target._id}>
                                                <MDBCol center >{'deleted'}</MDBCol>
                                                <MDBCol center >{'deleted'}</MDBCol>
                                                <MDBCol center >{t.quantity}</MDBCol>
                                                <MDBCol center >{target.createdAt.split('T')[0]}</MDBCol>
                                                <MDBCol center >{'deleted'}</MDBCol>
    
                                            </MDBRow>
                                        )
                                    }
                                })}
                                </MDBTableBody>

                            </MDBTable>

                        <div style={{padding:'20px 10px' ,display:'flex' ,flexDirection:'column'}}>

                            <span>Payment mode : <b> {target.online ? '100% - online payment':'10% - Complete payment during exchange '}</b></span>

                            <span>Status : <b style={{color:colorCode(target.status)}}>{ target.status}  </b></span>
                            <span>Total Price : <b>{target.totalPrice} XAF</b>  &nbsp;&nbsp;|&nbsp;&nbsp; Amount Paid : <b>{target.status == 'success' ? target.totalPrice:'0 XAF'}</b></span>
                            { !target.online && target.status == 'success' && <span>Remaining : <b>90%</b></span>}
                        </div>

                        {/* Message sending to the user by both email and sms */}
                        <div style={{borderTop:'solid 1px rgba(0,0,0,0.2)' ,padding:'10px'}}>
                            {/* <MDBInputGroup className='form-group'>
                                <span>Any message to the client ? </span>
                                <MDBInput value={message} onChange={e=>setMessage(e.target.value)} type='text'/>
                            </MDBInputGroup>

                            <MDBBtnGroup onClick={()=>mloading ? '':sendMessage(target.email ,target.phoneNumber)} style={{float:'right'}} >
                                <MDBBtn color='primary' disabled={mloading}>Send</MDBBtn>
                            </MDBBtnGroup>

                            <span style={{color:'crimson'}}>{merror}</span>
                            <span style={{color:'green'}}>{mm && 'Message sent sucessfully'}</span>  */}
                            <br/>
                        </div>


                    </MDBModalBody>
                </MDBModalContent>
            </MDBModal>

            }

            {
                transactions.length != 0 &&  withdrawModal &&
                <MDBModal open={withdrawModal} onClose={()=>setWithdrawModal(false)} >
                    <MDBModalContent style={{width:'fit-content' ,left:'45%' ,top:'10%' ,transform:'translate(-45%)' ,margin:'0px 5px'}} >
                        <MDBModalHeader>
                            <MDBModalTitle>Money Withdrawal</MDBModalTitle>
                        </MDBModalHeader>
                        
                        <MDBModalBody>
                            <div style={{textAlign:'center' ,margin:'10px 0px'}} >
                            {(withdrawModal.status == "pending" || withdrawModal.status == "failed") &&
                            <>
                                <b>No Withdrawal Available - <b style={{color:colorCode(withdrawModal.status)}} >{withdrawModal.status}</b></b>
                            </>}</div>
                            <div style={{textAlign:'center' ,margin:'10px 0px'}} ><b>{user.store.withdrawStatus && 'Amount removed'}</b></div>

                            <form onSubmit={e=>withDrawMoney(e)} >
                                <MDBInputGroup className='form-group' >
                                    <span>Confirmation code* </span>
                                    <MDBInput value={withdraw.code} onChange={e=>setWithdraw({...withdraw ,code:e.target.value})} required />
                                </MDBInputGroup><br/>
                            
                                <MDBInputGroup className='form-group' >
                                    <div style={{display:'flex'}} >
                                        <span>Receiver phone number* &nbsp;  </span>
                                        <div>
                                            <img src={require('../../assets/images/mtnMoney.jpg')} width="50px" height="50px" />&nbsp;|&nbsp; 
                                            <img src={require('../../assets/images/orangeMoney.jpg')} width="50px" height="50px"  />    
                                        </div>

                                    </div>
                                    <MDBInput value={withdraw.phoneNumber} onChange={e=>setWithdraw({...withdraw ,phoneNumber:e.target.value})} required />
                                </MDBInputGroup><br/>
                                
                                    <div style={{padding:'5px 0px ',textAlign:'center' ,border:'solid 0px red'}} >
                                        {withdrawError && <span style={{color:'crimson'}} >{withdrawError}</span>}
                                        {withdrawMsg && <span style={{color:'darkgreen'}} > {withdrawMsg} </span>}
                                    </div>

                                <MDBBtnGroup style={{float:'right'}}  >
                                    <MDBBtn color='dark' onClick={()=>setWithdrawModal(false)} >Close</MDBBtn>
                                    <MDBBtn 
                                        disabled={withdrawModal.status == "pending" || withdrawModal.status == "failed" || withdrawLoading }
                                    > withdraw </MDBBtn>
                                </MDBBtnGroup><br/><br/>


                            </form>

                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModal>
            }




            <div  style={{marginTop:'15px',padding:'20px'}}>
                <h3> <span style={{fontSize:'small' ,fontWeight:'bold' ,cursor:'pointer'}} onClick={()=>navigate("/store")}><i className='fas fa-arrow-left'></i> back <br/> </span>
                    Transactions ({transactions && transactions.length}) </h3>
                    <div>

                        <span style={{color:'crimson'}}>{error }</span>

                        {
                        loading ? 'Loading Transactions ...':
                        <>

                            <MDBTable bordered responsive striped style={{border:'solid 1px grey' ,minWidth:'850px'}}>
                                <MDBTableHead dark>
                                    <MDBRow >
                                        <MDBCol center>Total (XAF)</MDBCol>
                                        <MDBCol center>Date</MDBCol>
                                        {/* <MDBCol center>Number</MDBCol> */}
                                        <MDBCol center>Payment</MDBCol>
                                        {/* <MDBCol center>Reference</MDBCol> */}
                                        <MDBCol center>Location</MDBCol>
                                        <MDBCol center>Status</MDBCol>
                                        <MDBCol center>Actions</MDBCol>
                                    </MDBRow>

                                </MDBTableHead>

                                <MDBTableBody>
                                    {displayTransactions}
                                </MDBTableBody>

                            </MDBTable>

                        </>
                        }
                    </div>

            </div>
        </React.Fragment>
    )
}


export default Transactions

const Record = ({transaction ,deleteTransaction ,viewMore ,setWithdrawModal})=>{
    const {totalPrice ,createdAt ,status ,phoneNumber ,online ,reference ,email ,location} = transaction

function colorCode(status){
    switch(status){
        case 'pending':
            return 'orange';
        case 'failed':
            return 'red';
        case 'success':
            return 'green'
    }
}
    
    return(
        <MDBRow style={{backgroundColor:transaction.withdrawStatus || !transaction.online ? 'rgba(0,0,0,0.1)':''}} >  
            <MDBCol center>{totalPrice}</MDBCol>
            <MDBCol center>{createdAt.split('T')[0]}</MDBCol>
            {/* <MDBCol center>{phoneNumber}</MDBCol> */}
            <MDBCol center>{online ? "100%":"10%"}</MDBCol>
            {/* <MDBCol center>{reference}</MDBCol> */}
            <MDBCol center>{location}</MDBCol>

            <MDBCol center style={{color:colorCode(status)}}>{status}</MDBCol>
            <MDBCol center style={{cursor:'pointer' ,border:'solid 0px red' ,justifyContent:'space-evenly' ,display:'flex'}}>
                 <span style={{color:'darkblue' ,border:'solid 1px grey' ,padding:'5px'}} className='fas fa-eye' 
                 title="view transaction" onClick={()=>viewMore(transaction)}></span>
                <span style={{color:'darkgreen' ,border:'solid 1px grey' ,padding:'5px' ,display:online ? '':'none'}} className='fas fa-hand-holding-usd'
                title="withdraw/take money"  onClick={()=>setWithdrawModal(transaction)}></span>
                    { transaction.withdrawStatus &&
                        <span style={{color:'crimson',border:'solid 1px grey' ,padding:'5px'}} className='fas fa-trash' onClick={()=>deleteTransaction(transaction._id)}></span>
                    }
            </MDBCol>
        </MDBRow>            
    )
}