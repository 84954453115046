import { FormattedMessage } from "react-intl"

const routes = [
    {
        path:"/",
        title:<FormattedMessage id="home"/>,
        icon:'fas fa-home'
    },
    {
        path:"/auths/login",
        title:"Become a seller",
        icon:'fas fa-store',

    },
    {
        path:"/about-us",
        title:<FormattedMessage id="about"/>,
        icon:'fas fa-cube'

    },
    {
        path:"/help",
        title:<FormattedMessage id="help"/>,
        icon:'far fa-circle-question'

    },
    {
        path:"logout",
        title:"Log-out",
        icon:'fas fa-door-open'
    },
]

export default routes